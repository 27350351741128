define("ember-svg-jar/inlined/ee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\" stroke-width=\"1pt\"><rect width=\"640\" height=\"477.9\" rx=\"0\" ry=\"0\"/><rect width=\"640\" height=\"159.3\" y=\"320.7\" fill=\"#fff\" rx=\"0\" ry=\"0\"/><path fill=\"#1791ff\" d=\"M0 0h640v159.3H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});
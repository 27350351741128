define("ember-svg-jar/inlined/nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#21468b\" d=\"M0 0h640v480H0z\"/><path fill=\"#fff\" d=\"M0 0h640v320H0z\"/><path fill=\"#ae1c28\" d=\"M0 0h640v160H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 640 480"
    }
  };
});